import React from "react";
import { Button, ButtonGroup } from "@mui/material";
import Icon from "atlas/components/Icon/Icon";

const MoveButtons = ({ moveItem, item, showMoveUp = false, showMoveDown = false, setHoverStatus = () => {} }) => {
	const handleMoveUp = (event) => {
		event.stopPropagation();
		moveItem(item, true);
	};

	const handleMoveDown = (event) => {
		event.stopPropagation();
		moveItem(item, false);
	};

	return (
		<ButtonGroup variant="contained" color="primary" aria-label="split button">
			{showMoveUp && (
				<Button
					disableRipple
					variant="outlined"
					className="toc-move-button"
					onClick={(e) => handleMoveUp(e)}
					data-cy={`move-up-${item.guid}`}
				>
					<Icon name="expand-up" size={18} color={"#455D82"} />
				</Button>
			)}
			{showMoveDown && (
				<Button
					disableRipple
					className="toc-move-button"
					variant="outlined"
					onClick={(e) => handleMoveDown(e)}
					data-cy={`move-down-${item.guid}`}
					onBlur={() => setHoverStatus(false)}
				>
					<Icon name="expand-down" size={18} color={"#455D82"} />
				</Button>
			)}
		</ButtonGroup>
	);
};

export default MoveButtons;
