import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import { Locked } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { Presentation } from "../Icons";
import MoveButtons from "./CommonComponents/MoveButtons";
import { isSingleLine, getElement } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCHeading = (props) => {
	const {
		section: { fields },
		section,
		isSubHeading,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		notReallyAnItem,
		toggleTableOfContent,
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		openGoalsPopup,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		setActiveId = () => {},
		moveItem = () => {},
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const tableOfContentsClassesLimit1 = useTableOfContentsStyles({ lineLimit: 1 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);

	const dragProps = canDrag ? { dragId: section.guid, dragComponent: "div" } : {};

	const droppable = useDroppable({ id: section.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);
		moveItem(item, isMoveUp);
	};

	return (
		!section.deleted && (
			<TocContentWrapper
				condition={isAgenda || isMinutes}
				wrapper="span"
				wrapperProps={{
					id: `${section.guid}-toc-heading`,
					ref: setNodeRef,
				}}
			>
				{isMemberOnlySection && !isSubHeading && (
					<li className="member-only-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box color="common.white">{t("memberOnlySection")}</Box>
						</Typography>
						<Box className="margin-top-bottom-auto" color="common.white">
							<Locked fontSize="small" className={`${canDrag ? "" : "toc-locked-icon"}`} />
						</Box>
					</li>
				)}
				{isConsentSection && !isSubHeading && (
					<li className="consent-section-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("consentSection")}</Box>
						</Typography>
					</li>
				)}
				{isPublicCommentSection && !isSubHeading && (
					<li className={`${isPdfSidebar ? "public-comment-section-toc-header-pdfsidebar" : "public-comment-section-toc-header"}`}>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("publicCommentSection")}</Box>
						</Typography>
					</li>
				)}
				<ListItem
					id={`${idPrefix}-${section.guid}`}
					data-cy={`${idPrefix}-${section.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: section.original && section.original.closed && !section.fields.Closed.Value,
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx(canDrag ? "candrag-outline-item" : "outline-item", {
							"toc-padding": hoverStatus && canDrag,
							"toc-active-item": isActive && canDrag,
							"outline-sub-heading": isSubHeading,
							"outline-heading": !isSubHeading,
							"member-only-section": isMemberOnlySection,
							"consent-section-outline": isConsentSection,
							"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
							"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
							"closed-meeting": isClosedMeeting,
							"pdfSideBar pdfSideBarHeading": isPdfSidebar,
							"member-only-section-last-child-item": isLastChildItem && isMemberOnlySection && canDrag,
							"consent-section-last-child-item": isLastChildItem && isConsentSection && canDrag,
							"public-comment-section-last-child-item": isLastChildItem && isPublicCommentSection && canDrag,
							hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(section.guid) && !clickedOnAttachment,
						}),
					}}
					onMouseOver={() => setHoverStatus(true)}
					onMouseOut={() => setHoverStatus(false)}
					onFocus={() => setHoverStatus(true)}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(section);
									setClickedOnAttachment(false);
									dispatch(setActive(section.guid, true));
									setActiveId(section.guid);
								}
							: button
								? () => {
										telemetryAddEvent(`${telemetryPage} - Outline navigation`);

										dispatch(setActive(section.guid, true));
										setActiveId(section.guid);
										if (isSmallDevice) {
											toggleTableOfContent();
										}
									}
								: undefined
					}
					selected={selected && selected.includes(section.guid)}
				>
					{notReallyAnItem ? (
						<ListItemText primaryTypographyProps={{ variant: "h5" }} primary={fields.Name.Value} />
					) : (
						<>
							{canDrag && (
								<Draggable {...dragProps}>
									<DragHandle role="button" />
								</Draggable>
							)}
							<Typography variant="h5" component="span" className="outline-number">
								{fields.Number.Value}
							</Typography>
							<ListItemText
								className={clsx({
									[tableOfContentsClasses.limitLines]: canDrag ? !isSingleLine(getElement(`${idPrefix}-${section.guid}`)) : true,
									[tableOfContentsClassesLimit1.limitLines]: canDrag && isSingleLine(getElement(`${idPrefix}-${section.guid}`)),
								})}
								primaryTypographyProps={{ variant: "h5" }}
								primary={
									<TOCPrimaryText
										text={fields.Name.Value}
										parserOptions={parserOptions}
										showTruncationTooltip
										truncationThreshold={isPdfSidebar ? 23 : 50}
										isPdfSidebar={isPdfSidebar}
									></TOCPrimaryText>
								}
							/>
							{hoverStatus && canDrag && (
								<MoveButtons
									moveItem={handleMove}
									item={section}
									showMoveUp={getNextAvailablePosition(section, true).nextPosition !== null}
									showMoveDown={getNextAvailablePosition(section, false).nextPosition !== null}
									setHoverStatus={setHoverStatus}
								/>
							)}
							{!hoverStatus && selected && selected.includes(section.guid) && <Presentation />}
						</>
					)}
				</ListItem>
				<TOCAttachments
					attachments={section.attachments}
					item={section}
					itemGuid={section.guid}
					isMemberOnlySection={isMemberOnlySection}
					isConsentSection={isConsentSection}
					isPublicCommentSection={isPublicCommentSection}
					toggleTableOfContent={toggleTableOfContent}
					isSmallDevice={isSmallDevice}
					setActive={setActive}
					telemetryPage={telemetryPage}
					setDataForAttachmentDocument={setDataForAttachmentDocument}
					isPdfSidebar={isPdfSidebar}
					clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
					selectedId={selectedId}
					openGoalsPopup={openGoalsPopup}
					isLastChildItem={isLastChildItem}
					canDrag={canDrag}
				/>
			</TocContentWrapper>
		)
	);
};

export default React.memo(TOCHeading);
