import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import Icon from "atlas/components/Icon/Icon";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import MoveButtons from "./CommonComponents/MoveButtons";
import { Presentation } from "../Icons";
import { isSingleLine, getElement } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCDecision = (props) => {
	const {
		resolution,
		toggleTableOfContent = () => {},
		isConsentSection,
		isPublicCommentSection,
		isMemberOnlySection,
		isSmallDevice,
		isHeadingAction,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		setActiveId = () => {},
		moveItem = () => {},
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
	} = props;
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const tableOfContentsClassesLimit1 = useTableOfContentsStyles({ lineLimit: 1 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);

	const dragProps = canDrag ? { dragId: resolution.guid, dragComponent: "div" } : {};

	const droppable = useDroppable({ id: resolution.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);
		moveItem(item, isMoveUp);
	};

	return (
		<TocContentWrapper
			condition={isAgenda || isMinutes}
			wrapper="span"
			wrapperProps={{
				id: `${resolution.guid}-toc-decision`,
				ref: setNodeRef,
			}}
		>
			<ListItem
				id={`${idPrefix}-${resolution.guid}`}
				data-cy={`${idPrefix}-${resolution.guid}`}
				className={clsx(tableOfContentsClasses.listItem, {
					[tableOfContentsClasses.closedChanged]: resolution.original && resolution.original.closed && !resolution.fields.Closed.Value,
				})}
				button={button}
				role=""
				component={"li"}
				disableGutters
				divider
				classes={{
					root: clsx(canDrag ? "candrag-outline-item-secondary" : "outline-item-secondary", {
						"toc-padding": hoverStatus && canDrag,
						"toc-active-item": isActive && canDrag,
						"outline-item-consent-recommendation": isConsentSection && !canDrag,
						"candrag-outline-item-consent-recommendation": isConsentSection && canDrag,
						"outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection && !canDrag,
						"candrag-outline-item-public-comment-recommendation": isPublicCommentSection && !isConsentSection && canDrag,
						"outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection && !canDrag,
						"candrag-outline-heading-recommendation": isHeadingAction && !isPublicCommentSection && !isConsentSection && canDrag,
						"outline-item-recommendation": !isHeadingAction && !isPublicCommentSection && !isConsentSection,
						"member-only-section": isMemberOnlySection,
						"consent-section-outline": isConsentSection,
						"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
						"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
						"closed-meeting": isClosedMeeting,
						"member-only-section-last-child-item": isLastChildItem && isMemberOnlySection && canDrag,
						"consent-section-last-child-item": isLastChildItem && isConsentSection && canDrag,
						"public-comment-section-last-child-item": isLastChildItem && isPublicCommentSection && canDrag,
						pdfSideBar: isPdfSidebar,
						hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(resolution.guid) && !clickedOnAttachment,
					}),
				}}
				onMouseOver={() => setHoverStatus(true)}
				onMouseOut={() => setHoverStatus(false)}
				onFocus={() => setHoverStatus(true)}
				onClick={
					button && isPdfSidebar
						? () => {
								setDataForOutlineItem(resolution);
								setClickedOnAttachment(false);
								dispatch(setActive(resolution.guid, true));
								setActiveId(resolution.guid);
							}
						: button
							? () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);

									dispatch(setActive(resolution.guid, true));
									setActiveId(resolution.guid);
									if (isSmallDevice) {
										toggleTableOfContent();
									}
								}
							: undefined
				}
				selected={selected && selected.includes(resolution.guid)}
			>
				{canDrag && (
					<Draggable {...dragProps}>
						<DragHandle role="button" />
					</Draggable>
				)}
				<ListItemIcon style={{ minWidth: "0", top: "4px!important" }}>
					<Icon name="recommendation" />
				</ListItemIcon>
				<ListItemText
					className={clsx({
						[tableOfContentsClasses.limitLines]: canDrag ? !isSingleLine(getElement(`${idPrefix}-${resolution.guid}`)) : true,
						[tableOfContentsClassesLimit1.limitLines]: canDrag && isSingleLine(getElement(`${idPrefix}-${resolution.guid}`)),
					})}
					primaryTypographyProps={{ variant: "h5" }}
					primary={
						<TOCPrimaryText
							text={resolution.fields.Name.Value}
							parserOptions={parserOptions}
							showTruncationTooltip
							truncationThreshold={isPdfSidebar ? 23 : 50}
							isPdfSidebar={isPdfSidebar}
						></TOCPrimaryText>
					}
				/>
				{hoverStatus && canDrag && (
					<MoveButtons
						moveItem={handleMove}
						item={resolution}
						showMoveUp={getNextAvailablePosition(resolution, true).nextPosition !== null}
						showMoveDown={getNextAvailablePosition(resolution, false).nextPosition !== null}
						setHoverStatus={setHoverStatus}
					/>
				)}
				{!hoverStatus && selected && selected.includes(resolution.guid) && <Presentation />}
			</ListItem>

			<TOCAttachments
				attachments={resolution.attachments}
				item={resolution}
				itemGuid={resolution.guid}
				isMemberOnlySection={isMemberOnlySection}
				isConsentSection={isConsentSection}
				isPublicCommentSection={isPublicCommentSection}
				toggleTableOfContent={toggleTableOfContent}
				telemetryPage={telemetryPage}
				setDataForAttachmentDocument={setDataForAttachmentDocument}
				isPdfSidebar={isPdfSidebar}
				setActive={setActive}
				clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
				isRecommendationLevel
				selectedId={selectedId}
				openGoalsPopup={openGoalsPopup}
				isLastChildItem={isLastChildItem}
				canDrag={canDrag}
			/>
		</TocContentWrapper>
	);
};

export default TOCDecision;
